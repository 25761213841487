import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { JwtInterceptor, ServerErrorInterceptor } from './interceptors';
import { AuthService } from './services/base/00auth.service';
import { CommonService } from './utils/common.service';
import { TinhTpService } from './services/base/50tinh-tp.service';
import { QuanHuyenService } from './services/base/60quan-huyen.service';
import { PhuongXaService } from './services/base/70phuong-xa.service';
import { DanhMucTinTucService } from './services/base/80danh-muc-tin-tuc.service';
import { TinTucService } from './services/base/90tin-tuc.service';
import { ThongTinNganHangService } from './services/base/100thong-tin-ngan-hang.service';
import { HangMucXayDungService } from './services/base/110hang-muc-xay-dung.service';
import { DieuKhoanChinhSachService } from './services/base/120dieu-khoan-chinh-sach.service';
import { LienHeCongTyService } from './services/base/130lien-he-cong-ty.service';
import { ThongBaoService } from './services/base/140thong-bao.service';
import { ThongTinFSSService } from './services/base/150thong-tin-f-s-s.service';
import { NhanVienService } from './services/base/160nhan-vien.service';
import { DuAnNhanVienService } from './services/base/170du-an-nhan-vien.service';
import { QuyCheCongTyService } from './services/base/180quy-che-cong-ty.service';
import { BangLuongService } from './services/base/190bang-luong.service';
import { CongViecNhanVienService } from './services/base/200cong-viec-nhan-vien.service';
import { ThuChiNhanVienService } from './services/base/210thu-chi-nhan-vien.service';
import { KhoHangService } from './services/base/220kho-hang.service';
import { VatTuService } from './services/base/230vat-tu.service';
import { DonViCungCapService } from './services/base/240don-vi-cung-cap.service';
import { XuatNhapKhoService } from './services/base/250xuat-nhap-kho.service';
import { BaoCaoNhanVienService } from './services/base/260bao-cao-nhan-vien.service';
import { ChamCongService } from './services/base/270cham-cong.service';
import { NhomDichVuService } from './services/base/280nhom-dich-vu.service';
import { LoaiCongViecService } from './services/base/290loai-cong-viec.service';
import { BangGiaDonHangService } from './services/base/300bang-gia-don-hang.service';
import { TrangThaiDonHangService } from './services/base/310trang-thai-don-hang.service';
import { HinhThucThanhToanService } from './services/base/320hinh-thuc-thanh-toan.service';
import { TrangThaiThanhToanService } from './services/base/330trang-thai-thanh-toan.service';
import { DonDichVuService } from './services/base/340don-dich-vu.service';
import { ChiTietVatTuService } from './services/base/350chi-tiet-vat-tu.service';
import { ThongSoKyThuatService } from './services/base/360thong-so-ky-thuat.service';
import { PhanHoiDonDichVuService } from './services/base/370phan-hoi-don-dich-vu.service';
import { DanhSachBaoGiaDonDichVuService } from './services/base/380danh-sach-bao-gia-don-dich-vu.service';
import { LichSuGiaoDichService } from './services/base/390lich-su-giao-dich.service';
import { TuyenDungService } from './services/base/400tuyen-dung.service';
import { HinhThucLamViecService } from './services/base/410hinh-thuc-lam-viec.service';
import { ThoiGianLamViecService } from './services/base/420thoi-gian-lam-viec.service';
import { SoNamKinhNghiemService } from './services/base/430so-nam-kinh-nghiem.service';
import { TrinhDoHocVanService } from './services/base/440trinh-do-hoc-van.service';
import { BangGiaDangTinService } from './services/base/450bang-gia-dang-tin.service';
import { ChuyenNganhChinhService } from './services/base/460chuyen-nganh-chinh.service';
import { BangGiaLocHoSoService } from './services/base/470bang-gia-loc-ho-so.service';
import { MucLuongDuKienService } from './services/base/480muc-luong-du-kien.service';
import { DanhSachUngTuyenService } from './services/base/490danh-sach-ung-tuyen.service';
import { DangKyViecMoiService } from './services/base/500dang-ky-viec-moi.service';
import { LoaiNhanVienService } from './services/base/510loai-nhan-vien.service';
import { TrinhDoService } from './services/base/520trinh-do.service';
import { ChuyenMonService } from './services/base/530chuyen-mon.service';
import { LoaiTotNghiepService } from './services/base/540loai-tot-nghiep.service';
import { ChucVuService } from './services/base/550chuc-vu.service';
import { BangBangCapService } from './services/base/560bang-bang-cap.service';
import { KeKhaiKinhNghiemService } from './services/base/570ke-khai-kinh-nghiem.service';
import { NgoaiNguService } from './services/base/580ngoai-ngu.service';
import { TinHocService } from './services/base/590tin-hoc.service';
import { DuAnKhachHangService } from './services/base/600du-an-khach-hang.service';
import { TrangThaiDuAnService } from './services/base/610trang-thai-du-an.service';
import { DanhSachThoThauBaoGiaService } from './services/base/620danh-sach-tho-thau-bao-gia.service';
import { TrangThaiBaoGiaService } from './services/base/630trang-thai-bao-gia.service';
import { TaiKhoanService } from './services/base/640tai-khoan.service';
import { LoaiTaiKhoanService } from './services/base/650loai-tai-khoan.service';
import { NhomCuaHangService } from './services/base/660nhom-cua-hang.service';
import { MatHangDacTrungService } from './services/base/670mat-hang-dac-trung.service';
import { DangKyThueService } from './services/base/680dang-ky-thue.service';
import { DangKyHopDongSBSService } from './services/base/690dang-ky-hop-dong-s-b-s.service';
import { DangKyBaoHiemService } from './services/base/700dang-ky-bao-hiem.service';
import { GiayChungNhanSucKhoeService } from './services/base/710giay-chung-nhan-suc-khoe.service';
import { DonHangService } from './services/base/720don-hang.service';
import { ChiTietDonHangService } from './services/base/730chi-tiet-don-hang.service';
import { SanPhamService } from './services/base/740san-pham.service';
import { DanhMucSanPhamService } from './services/base/750danh-muc-san-pham.service';
import { KhoHangDaiLyService } from './services/base/760kho-hang-dai-ly.service';
import { LoaiVanChuyenService } from './services/base/770loai-van-chuyen.service';
import { BangGiaPhiVanChuyenService } from './services/base/780bang-gia-phi-van-chuyen.service';
import { DanhGiaDaiLyService } from './services/base/790danh-gia-dai-ly.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    CommonService,
    AuthService,
    TinhTpService,
    QuanHuyenService,
    PhuongXaService,
    DanhMucTinTucService,
    TinTucService,
    ThongTinNganHangService,
    HangMucXayDungService,
    DieuKhoanChinhSachService,
    LienHeCongTyService,
    ThongBaoService,
    ThongTinFSSService,
    NhanVienService,
    DuAnNhanVienService,
    QuyCheCongTyService,
    BangLuongService,
    CongViecNhanVienService,
    ThuChiNhanVienService,
    KhoHangService,
    VatTuService,
    DonViCungCapService,
    XuatNhapKhoService,
    BaoCaoNhanVienService,
    ChamCongService,
    NhomDichVuService,
    LoaiCongViecService,
    BangGiaDonHangService,
    TrangThaiDonHangService,
    HinhThucThanhToanService,
    TrangThaiThanhToanService,
    DonDichVuService,
    ChiTietVatTuService,
    ThongSoKyThuatService,
    PhanHoiDonDichVuService,
    DanhSachBaoGiaDonDichVuService,
    LichSuGiaoDichService,
    TuyenDungService,
    HinhThucLamViecService,
    ThoiGianLamViecService,
    SoNamKinhNghiemService,
    TrinhDoHocVanService,
    BangGiaDangTinService,
    ChuyenNganhChinhService,
    BangGiaLocHoSoService,
    MucLuongDuKienService,
    DanhSachUngTuyenService,
    DangKyViecMoiService,
    LoaiNhanVienService,
    TrinhDoService,
    ChuyenMonService,
    LoaiTotNghiepService,
    ChucVuService,
    BangBangCapService,
    KeKhaiKinhNghiemService,
    NgoaiNguService,
    TinHocService,
    DuAnKhachHangService,
    TrangThaiDuAnService,
    DanhSachThoThauBaoGiaService,
    TrangThaiBaoGiaService,
    TaiKhoanService,
    LoaiTaiKhoanService,
    NhomCuaHangService,
    MatHangDacTrungService,
    DangKyThueService,
    DangKyHopDongSBSService,
    DangKyBaoHiemService,
    GiayChungNhanSucKhoeService,
    DonHangService,
    ChiTietDonHangService,
    SanPhamService,
    DanhMucSanPhamService,
    KhoHangDaiLyService,
    LoaiVanChuyenService,
    BangGiaPhiVanChuyenService,
    DanhGiaDaiLyService,

  ],
})
export class CoreModule { }
