<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<app-layout *ngIf="isLoggedIn$ | async; else notAuthorized">
    <router-outlet></router-outlet>
</app-layout>

<ng-template #notAuthorized>
    <router-outlet></router-outlet>
</ng-template>