import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { TinTuc } from '../../models/90tin-tuc.model';
import { Constant } from '../../utils/constaint.utils';

@Injectable({
  providedIn: 'root',
})
export class TinTucService {
  // Define API
  apiURL = Constant.api.frontend_url;

  constructor(private http: HttpClient) {}

  /*========================================
    Begin CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  /**
   * HttpClient API get() method => Fetch tin-tucs list
   * @returns
   */
  get(): Observable<any> {
    return this.http
      .get<TinTuc>(this.apiURL + '/tin-tucs')
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API post() method => Create tinTuc
   * @param tinTuc
   * @returns
   */
  add(tinTuc: TinTuc): Observable<TinTuc> {
    return this.http
      .post<TinTuc>(
        this.apiURL + '/tin-tucs',
        JSON.stringify(tinTuc),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API put() method => Update tinTuc
   * @param id
   * @param tinTuc
   * @returns
   */
  update(id: any, tinTuc: TinTuc): Observable<TinTuc> {
    return this.http
      .put<TinTuc>(
        this.apiURL + '/tin-tucs',
        JSON.stringify(tinTuc),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API delete() method => Delete tinTuc
   * @param id
   * @returns
   */
  delete(id: any) {
    return this.http
      .delete<TinTuc>(this.apiURL + '/tin-tucs/' + id, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API get() method => Fetch tinTuc
   * @param id
   * @returns
   */
  find(id: any): Observable<TinTuc> {
    return this.http
      .get<TinTuc>(this.apiURL + '/tin-tucs/' + id)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API get() method => paginate tinTuc with start page = 1 and limit
   * @param id
   * @returns
   */
  paginate(page: Number, limit: Number, filter: any): Observable<TinTuc> {
    let url =
      this.apiURL + '/tin-tucs/paginate?page=' + page + '&limit=' + limit;

    // add condition filter
    if (filter != '') {
      url =
        this.apiURL +
        '/tin-tucs/paginate?page=' +
        page +
        '&limit=' +
        limit +
        filter;
    }
    return this.http
      .get<TinTuc>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  /*========================================
    Begin Custom Methods for RESTful API
  =========================================*/

  up(id: string): Observable<any> {
    return this.http
      .put(`${this.apiURL}/tin-tucs/up`, { id })
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Error handling
   * @param error
   * @returns
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
