import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { User } from '../../models/10user.models';
import { getItem, removeItem, setItem, StorageItem } from '../../utils';
import { Constant } from '../../utils/constaint.utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn$ = new BehaviorSubject<boolean>(!!getItem(StorageItem.Auth));

  /**
   * isLoggedIn
   */
  get isLoggedIn(): boolean {
    return this.isLoggedIn$.getValue();
  }

  // Define API
  apiURL = Constant.api.frontend_url;

  constructor(private http: HttpClient) { }

  /*========================================
    Begin CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  /**
   * HttpClient API register() method
   * @param user 
   * @returns 
   */
  register(user: User): Observable<User> {
    return this.http.post<User>(this.apiURL + '/auth/register', JSON.stringify(user), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API login() method 
   * @param email 
   * @param password 
   * @returns 
   */
  login(email: String, password: String): Observable<any> {
    const param = { email: email, password: password };
    return this.http.post<any>(this.apiURL + '/auth/login', JSON.stringify(param), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * login Success
   * @param access 
   */
  loginSuccess(access: String): void {
    console.log('phuong', access);
    // update access token
    setItem(StorageItem.Auth, access);
    this.isLoggedIn$.next(true);
  }

  /**
   * HttpClient API logout() method 
   * @param refreshToken 
   * @returns 
   */
  logout(refreshToken: String): Observable<any> {
    const param = { refreshToken: refreshToken };

    // remove access token
    removeItem(StorageItem.Auth);
    this.isLoggedIn$.next(false);

    return this.http.post<any>(this.apiURL + '/auth/logout', JSON.stringify(param), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API refresh tokens method
   * @param refreshToken 
   * @returns 
   */
  refreshTokens(refreshToken: String): Observable<any> {
    const param = { refreshToken: refreshToken };

    return this.http.post<any>(this.apiURL + '/auth/refresh-tokens-admin', JSON.stringify(param), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API forgot password method 
   * @param email 
   * @returns 
   */
  forgotPassword(email: String): Observable<any> {
    const param = { email: email };

    return this.http.post<any>(this.apiURL + '/auth/forgot-password', JSON.stringify(param), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API reset password method
   * @param token 
   * @param newPassword 
   * @returns 
   */
  resetPassword(token: String, newPassword: String): Observable<any> {
    const param = { token: token, newPassword: newPassword };

    return this.http.post<any>(this.apiURL + '/auth/reset-password', JSON.stringify(param), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API send verification email method
   * @param user 
   * @returns 
   */
  sendVerificationEmail(user: User): Observable<any> {
    return this.http.post<any>(this.apiURL + '/auth/send-verification-email', JSON.stringify(user), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API verify email method
   * @param token 
   * @returns 
   */
  verifyEmail(token: String): Observable<any> {
    const param = { token: token };

    return this.http.post<any>(this.apiURL + '/auth/verify-email', JSON.stringify(param), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /*========================================
    Begin Custom Methods for RESTful API
  =========================================*/

  /**
   * Get Permission Of Component
   * @param userId 
   * @param slug 
   * @returns 
   */
  getPermissionOfComponent(userId, slug) {
    let URL = this.apiURL + `/auth/permission`;
    let condition = {
      'userId': userId,
      'slug': slug
    };
    return this.http.post<any>(URL, condition)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  /**
   * Error handling 
   * @param error 
   * @returns 
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

}