import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SanPham } from '../../models/740san-pham.model';
import { Constant } from '../../utils/constaint.utils';

@Injectable({
  providedIn: 'root'
})

export class SanPhamService {

  // Define API
  apiURL = Constant.api.frontend_url;

  constructor(private http: HttpClient) { }

  /*========================================
    Begin CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  /**
   * HttpClient API get() method => Fetch san-phams list
   * @returns 
   */
  get(): Observable<any> {
    return this.http.get<SanPham>(this.apiURL + '/san-phams')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API post() method => Create sanPham
   * @param sanPham 
   * @returns 
   */
  add(sanPham: SanPham): Observable<SanPham> {
    return this.http.post<SanPham>(this.apiURL + '/san-phams', JSON.stringify(sanPham), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API put() method => Update sanPham
   * @param id 
   * @param sanPham 
   * @returns 
   */
  update(id: any, sanPham: SanPham): Observable<SanPham> {
    return this.http.put<SanPham>(this.apiURL + '/san-phams', JSON.stringify(sanPham), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API delete() method => Delete sanPham
   * @param id 
   * @returns 
   */
  delete(id: any) {
    return this.http.delete<SanPham>(this.apiURL + '/san-phams/' + id, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
  * HttpClient API get() method => Fetch sanPham
  * @param id 
  * @returns 
  */
  find(id: any): Observable<SanPham> {
    return this.http.get<SanPham>(this.apiURL + '/san-phams/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
  * HttpClient API get() method => paginate sanPham with start page = 1 and limit
  * @param id 
  * @returns 
  */
  paginate(page: Number, limit: Number, filter: any): Observable<SanPham> {
    let url = this.apiURL + '/san-phams/paginate?page=' + page + '&limit=' + limit;

    // add condition filter
    if (filter != '') {
      url = this.apiURL + '/san-phams/paginate?page=' + page + '&limit=' + limit + filter;
    }
    return this.http.get<SanPham>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /*========================================
    Begin Custom Methods for RESTful API
  =========================================*/

  /**
   * find San Pham By Ma San Pham
   * @param {*} maSanPham
   */
  findSanPhamByMaSanPham(maSanPham){
    let url = this.apiURL + `/san-phams/ma-san-pham?maSanPham=${maSanPham}`;
    return this.http.get<SanPham>(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  /**
   * Error handling 
   * @param error 
   * @returns 
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}