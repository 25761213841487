import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/base/00auth.service';
import { ROUTER_UTILS } from '../utils/router.utils';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );

  constructor(private router: Router, private tokenService: AuthService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    let authReq = request;
    return next.handle(authReq).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !authReq.url.includes('/login') &&
          error.status === 401
        ) {
          // Refesh token
          return this.handle401Error(authReq, next);
        }
        return this.logout(error);
      }),
    );
  }

  /**
   * handle401Error
   * @param request
   * @param next
   * @returns
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token: any = localStorage.getItem('refresh');
      if (token) {
        return this.tokenService.refreshTokens(token).pipe(
          switchMap((data: any) => {
            this.isRefreshing = false;

            // Save access token
            localStorage.setItem(
              'access',
              data['access']['token'],
            );

            // Save refresh token
            localStorage.setItem(
              'refresh',
              data['refresh']['token'],
            );

            this.refreshTokenSubject.next(data['access']['token']);

            return next.handle(
              this.addTokenHeader(request, data['access']['token']),
            );
          }),
          catchError((err) => {
            return this.logout(err);
          }),
        );
      }
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addTokenHeader(request, token))),
      );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token))),
    );
  }

  /**
   * addTokenHeader
   * @param request
   * @param token
   * @returns
   */
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * logout
   * @param error
   * @returns
   */
  logout(error: HttpErrorResponse) {
    //remove all local
    localStorage.clear();
    this.tokenService.isLoggedIn$.next(false);
    window.location.href = '/login';
    return throwError(error);
  }
}
