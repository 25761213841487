import { Component, OnInit } from '@angular/core';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { AuthService } from './@core/services/base/00auth.service';
import { SeoService } from './@core/services/seo';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLoggedIn$!: Observable<boolean>;
  url: string = '';
  constructor(private router: Router,
    private seoService: SeoService,
    private authService: AuthService,
    private spinner: NgxSpinnerService) {
    // check url change
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.spinner.show();
      }

      if (event instanceof NavigationEnd) {
        /** spinner ends after 1 seconds */
        setTimeout(() => {
          this.spinner.hide();
        }, 500)
      }

      if (event instanceof NavigationError) {
        /** spinner ends after 1 seconds */
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }

      const xxx = event as NavigationEnd;
      this.url = xxx.url;
      if(this.url === '/'){
        this.router.navigate(['/login']);
      }
    });
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
   
    this.runGlobalServices();
  }

  /**
   * runGlobalServices
   */
  private runGlobalServices(): void {
    this.seoService.init();
  }
}
