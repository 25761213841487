import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { DanhMucSanPham } from '../../models/750danh-muc-san-pham.model';
import { Constant } from '../../utils/constaint.utils';

@Injectable({
  providedIn: 'root',
})
export class DanhMucSanPhamService {
  // Define API
  apiURL = Constant.api.frontend_url;

  constructor(private http: HttpClient) {}

  /*========================================
    Begin CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  /**
   * HttpClient API get() method => Fetch danh-muc-san-phams list
   * @returns
   */
  get(): Observable<any> {
    return this.http
      .get<DanhMucSanPham>(this.apiURL + '/danh-muc-san-phams')
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API post() method => Create danhMucSanPham
   * @param danhMucSanPham
   * @returns
   */
  add(danhMucSanPham: DanhMucSanPham): Observable<DanhMucSanPham> {
    return this.http
      .post<DanhMucSanPham>(
        this.apiURL + '/danh-muc-san-phams',
        JSON.stringify(danhMucSanPham),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API put() method => Update danhMucSanPham
   * @param id
   * @param danhMucSanPham
   * @returns
   */
  update(id: any, danhMucSanPham: DanhMucSanPham): Observable<DanhMucSanPham> {
    return this.http
      .put<DanhMucSanPham>(
        this.apiURL + '/danh-muc-san-phams',
        JSON.stringify(danhMucSanPham),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API delete() method => Delete danhMucSanPham
   * @param id
   * @returns
   */
  delete(id: any) {
    return this.http
      .delete<DanhMucSanPham>(
        this.apiURL + '/danh-muc-san-phams/' + id,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API get() method => Fetch danhMucSanPham
   * @param id
   * @returns
   */
  find(id: any): Observable<DanhMucSanPham> {
    return this.http
      .get<DanhMucSanPham>(this.apiURL + '/danh-muc-san-phams/' + id)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API get() method => paginate danhMucSanPham with start page = 1 and limit
   * @param id
   * @returns
   */
  paginate(
    page: Number,
    limit: Number,
    filter: any
  ): Observable<DanhMucSanPham> {
    let url =
      this.apiURL +
      '/danh-muc-san-phams/paginate?page=' +
      page +
      '&limit=' +
      limit;

    // add condition filter
    if (filter != '') {
      url =
        this.apiURL +
        '/danh-muc-san-phams/paginate?page=' +
        page +
        '&limit=' +
        limit +
        filter;
    }
    return this.http
      .get<DanhMucSanPham>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  /*========================================
    Begin Custom Methods for RESTful API
  =========================================*/

  up(id: string): Observable<any> {
    return this.http
      .put(`${this.apiURL}/danh-muc-san-phams/up`, { id })
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Error handling
   * @param error
   * @returns
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
