import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BangGiaDangTin } from '../../models/450bang-gia-dang-tin.model';
import { Constant } from '../../utils/constaint.utils';

@Injectable({
  providedIn: 'root'
})

export class BangGiaDangTinService {

  // Define API
  apiURL = Constant.api.frontend_url;

  constructor(private http: HttpClient) { }

  /*========================================
    Begin CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  /**
   * HttpClient API get() method => Fetch bang-gia-dang-tins list
   * @returns 
   */
  get(): Observable<any> {
    return this.http.get<BangGiaDangTin>(this.apiURL + '/bang-gia-dang-tins')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API post() method => Create bangGiaDangTin
   * @param bangGiaDangTin 
   * @returns 
   */
  add(bangGiaDangTin: BangGiaDangTin): Observable<BangGiaDangTin> {
    return this.http.post<BangGiaDangTin>(this.apiURL + '/bang-gia-dang-tins', JSON.stringify(bangGiaDangTin), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API put() method => Update bangGiaDangTin
   * @param id 
   * @param bangGiaDangTin 
   * @returns 
   */
  update(id: any, bangGiaDangTin: BangGiaDangTin): Observable<BangGiaDangTin> {
    return this.http.put<BangGiaDangTin>(this.apiURL + '/bang-gia-dang-tins', JSON.stringify(bangGiaDangTin), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API delete() method => Delete bangGiaDangTin
   * @param id 
   * @returns 
   */
  delete(id: any) {
    return this.http.delete<BangGiaDangTin>(this.apiURL + '/bang-gia-dang-tins/' + id, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
  * HttpClient API get() method => Fetch bangGiaDangTin
  * @param id 
  * @returns 
  */
  find(id: any): Observable<BangGiaDangTin> {
    return this.http.get<BangGiaDangTin>(this.apiURL + '/bang-gia-dang-tins/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
  * HttpClient API get() method => paginate bangGiaDangTin with start page = 1 and limit
  * @param id 
  * @returns 
  */
  paginate(page: Number, limit: Number, filter: any): Observable<BangGiaDangTin> {
    let url = this.apiURL + '/bang-gia-dang-tins/paginate?page=' + page + '&limit=' + limit;

    // add condition filter
    if (filter != '') {
      url = this.apiURL + '/bang-gia-dang-tins/paginate?page=' + page + '&limit=' + limit + filter;
    }
    return this.http.get<BangGiaDangTin>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /*========================================
    Begin Custom Methods for RESTful API
  =========================================*/




  /**
   * Error handling 
   * @param error 
   * @returns 
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}