import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { NhanVien } from '../../models/160nhan-vien.model';
import { ResponseList } from '../../responses/shared/list-response';
import { Constant } from '../../utils/constaint.utils';

@Injectable({
  providedIn: 'root',
})
export class NhanVienService {
  // Define API
  apiURL = Constant.api.frontend_url;

  constructor(private http: HttpClient) {}

  /*========================================
    Begin CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  /**
   * HttpClient API get() method => Fetch nhan-viens list
   * @returns
   */
  get(): Observable<any> {
    return this.http
      .get<NhanVien>(this.apiURL + '/nhan-viens')
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API post() method => Create nhanVien
   * @param nhanVien
   * @returns
   */
  add(nhanVien: NhanVien): Observable<NhanVien> {
    return this.http
      .post<NhanVien>(
        this.apiURL + '/nhan-viens',
        JSON.stringify(nhanVien),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API put() method => Update nhanVien
   * @param id
   * @param nhanVien
   * @returns
   */
  update(id: any, nhanVien: NhanVien): Observable<NhanVien> {
    return this.http
      .put<NhanVien>(
        this.apiURL + '/nhan-viens',
        JSON.stringify(nhanVien),
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API delete() method => Delete nhanVien
   * @param id
   * @returns
   */
  delete(id: any) {
    return this.http
      .delete<NhanVien>(this.apiURL + '/nhan-viens/' + id, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API get() method => Fetch nhanVien
   * @param id
   * @returns
   */
  find(id: any): Observable<NhanVien> {
    return this.http
      .get<NhanVien>(this.apiURL + '/nhan-viens/' + id)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * HttpClient API get() method => paginate nhanVien with start page = 1 and limit
   * @param id
   * @returns
   */
  paginate(
    page: Number,
    limit: Number,
    filter: any
  ): Observable<ResponseList<NhanVien>> {
    let url =
      this.apiURL + '/nhan-viens/paginate?page=' + page + '&limit=' + limit;

    // add condition filter
    if (filter != '') {
      url =
        this.apiURL +
        '/nhan-viens/paginate?page=' +
        page +
        '&limit=' +
        limit +
        filter;
    }
    return this.http
      .get<ResponseList<NhanVien>>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  /*========================================
    Begin Custom Methods for RESTful API
  =========================================*/

  /**
   * Error handling
   * @param error
   * @returns
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert('Email đã  tồn tại từ trước');
    return throwError(errorMessage);
  }
}
