import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constant } from './constaint.utils';

 

@Injectable()
export class CommonService {
  // Define API
  public BASEURL = Constant.api.frontend_url;

  public IMAGE_UPLOAD_URL = this.BASEURL + '/uploads';
  public FILE_UPLOAD_URL = this.BASEURL + '/uploads';

  constructor(
    public httpClient: HttpClient,
    private toastrService: ToastrService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: Object,
    private spinner: NgxSpinnerService
  ) {
  }

  /**
   * format Html Tag
   * @param content
   */
  public formatHtmlTag(content) {
    let result: string;
    let dummyElem = document.createElement('DIV');
    dummyElem.innerHTML = content;
    document.body.appendChild(dummyElem);
    result = dummyElem.textContent;
    document.body.removeChild(dummyElem);
    return result;
  }

  /**
   * convert To Data
   * @param data
   */
  public convertToData(data): any[] {
    data = JSON.parse(data + '');
    let result: any[] = [];
    data.forEach((item) => {
      item.fields.id = item.pk;
      result.push(item.fields);
    });
    return result;
  }

  /**
   *
   * @param date
   */
  public formatDate(date: Date): string {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return (
      // date.getFullYear() +
      // '-' +
      // (month > 9 ? month : '0' + month) +
      // '-' +
      // (day > 9 ? day : '0' + day)
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '\x20' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) +
      ':' +
      (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())
    );
  }

  /**
   *
   * @param date
   */
  public formatDateTime(date: Date): string {
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '\x20' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) +
      ':' +
      (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())
    );
  }

  public showError(mess: string) {
    this.toastrService.error('Notification', mess, {
      timeOut: 1500,
      progressBar: true,
    });
  }

  public showSuccess(mess: string) {
    this.toastrService.info('Notification', mess + '!', {
      timeOut: 1500,
      progressBar: true,
    });
  }

  public showWarning(mess: string) {
    this.toastrService.warning('Notification', mess + '!', {
      timeOut: 1500,
      progressBar: true,
    });
  }

  /**
   * bỏ dấu tiếng việt để search
   */
  public cleanAccents(str: string): string {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    // Combining Diacritical Marks
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // huyền, sắc, hỏi, ngã, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // mũ â (ê), mũ ă, mũ ơ (ư)

    return str;
  }

  /**
   * upload image Core
   */
  public uploadImageCore(inputFileAvatar): Observable<any> {
    const uploadFileFinish = new Observable((observer) => {
      let files = [];
      const fileUpload = inputFileAvatar.nativeElement;

      fileUpload.onchange = () => {
        for (let index = 0; index < fileUpload.files.length; index++) {
          const file = fileUpload.files[index];
          files.push({ data: file, inProgress: false, progress: 0 });
        }

        // upload file
        inputFileAvatar.nativeElement.value = '';
        files.forEach((file) => {
          // upload file image
          const formData = new FormData();
          formData.append('files', file.data);
          file.inProgress = true;
          this.spinner.show();

          // post image to server
          this.httpClient
            .post<any>(this.IMAGE_UPLOAD_URL, formData, {})
            .subscribe((data) => {
              observer.next(data);
              this.spinner.hide();
            });
        });
      };
      fileUpload.click();
    });

    return uploadFileFinish;
  }

  /**
   * 
   */
  public uploadImageThumbnail(inputFileAvatar) {
    const uploadFileFinish = new Observable((observer) => {
      let files = [];
      const _URL = window.URL || window.webkitURL;
      const fileUpload = inputFileAvatar.nativeElement;
      let flag = true;

      fileUpload.onchange = () => {
        for (let index = 0; index < fileUpload.files.length; index++) {
          const file = fileUpload.files[index];

          // check size of image
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
              // Get width with height  of image
              const height = img.naturalHeight;
              const width = img.naturalWidth;

              // Check valid image size
              if (height > 300 || height < 250 || width > 300 || width < 250) {
               flag = false;
              }
            };
          };
          files.push({ data: file, inProgress: false, progress: 0 });
        }

        setTimeout(() => {
          if (flag) {
            // upload file
            inputFileAvatar.nativeElement.value = '';
            files.forEach((file) => {
              // upload file image
              const formData = new FormData();
              formData.append('files', file.data);
              file.inProgress = true;
              this.spinner.show();

              // post image to server
              this.httpClient
                .post<any>(this.IMAGE_UPLOAD_URL, formData, {})
                .subscribe((data) => {
                  observer.next(data);
                  this.spinner.hide();
                });
            });
          }else{
            return false;
          }
        }, 500);
      };
      fileUpload.click();
    });

    return uploadFileFinish;
  }


  /**
   * upload File Core
   */
  public uploadFileCore(inputFileAvatar): Observable<any> {
    const uploadFileFinish = new Observable((observer) => {
      let files = [];
      const fileUpload = inputFileAvatar.nativeElement;

      fileUpload.onchange = () => {
        for (let index = 0; index < fileUpload.files.length; index++) {
          const file = fileUpload.files[index];
          files.push({ data: file, inProgress: false, progress: 0 });
        }

        // upload file
        inputFileAvatar.nativeElement.value = '';
        files.forEach((file) => {
          // upload file
          const formData = new FormData();
          formData.append('files', file.data);
          file.inProgress = true;
          this.spinner.show();

          // post file to server
          this.httpClient
            .post<any>(this.IMAGE_UPLOAD_URL, formData, {})
            .subscribe((data) => {
              observer.next(data);
              this.spinner.hide();
            });
        });
      };
      fileUpload.click();
    });

    return uploadFileFinish;
  }

  /**
   * scroll to top with angular universal
   */
  public scrollToTop() {
    if (isPlatformBrowser(this.platform)) {
      window.scroll({ left: 0, top: 0 });
    }
  }

  /**
   * scroll to top with angular universal
   */
  public scrollToTopPosition(left, top) {
    if (isPlatformBrowser(this.platform)) {
      window.scroll({ left: left, top: top });
    }
  }

  /**
   * Notification to app
   * @param title 
   * @param body 
   * @param isAllDevice 
   * @param deviceToken 
   * @param token 
   * @returns 
   */
  public pushNotificationToApp(title: any, body: any, isAllDevice: boolean = false, deviceToken: string): Observable<any> {
    const apiToken = 'AAAA6b5yg0A:APA91bGyUfjHDOdIspPXspjnOfP8_VeceTBWXxXHujpPGFH6-1krLit1ONrfVihDALRbxlPNvpu_-Z68KJj-SetZnJtfZQCWYw_flmns_9EYrH0uYumRKOJjhi0-YqNgyvT_x24Q-R3J';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'key=' + apiToken
      }),
    };
    const fcmUrl = 'https://fcm.googleapis.com/fcm/send';

    let param = {
      "to": isAllDevice ? "/topics/fcm_fivess" : deviceToken,
      "notification": {
        "body": body,
        "title": title
      },
      "priority": "high",
      "data": {
        "click_action": "FLUTTER_NOTIFICATION_CLICK",
        "id": "1",
        "status": "done",
        "title": "title from data",
        "message": "message from data"
      }
    };
    // post file to server
    return this.httpClient
      .post<any>(fcmUrl, JSON.stringify(param), httpOptions);
  }
}
