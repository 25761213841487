import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { DonHang } from '../../models/720don-hang.model';
import { Constant } from '../../utils/constaint.utils';

@Injectable({
  providedIn: 'root'
})

export class DonHangService {

  // Define API
  apiURL = Constant.api.frontend_url;

  constructor(private http: HttpClient) { }

  /*========================================
    Begin CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  /**
   * HttpClient API get() method => Fetch don-hangs list
   * @returns 
   */
  get(): Observable<any> {
    return this.http.get<DonHang>(this.apiURL + '/don-hangs')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API post() method => Create donHang
   * @param donHang 
   * @returns 
   */
  add(donHang: DonHang): Observable<DonHang> {
    return this.http.post<DonHang>(this.apiURL + '/don-hangs', JSON.stringify(donHang), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API put() method => Update donHang
   * @param id 
   * @param donHang 
   * @returns 
   */
  update(id: any, donHang: DonHang): Observable<DonHang> {
    return this.http.put<DonHang>(this.apiURL + '/don-hangs', JSON.stringify(donHang), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API delete() method => Delete donHang
   * @param id 
   * @returns 
   */
  delete(id: any) {
    return this.http.delete<DonHang>(this.apiURL + '/don-hangs/' + id, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
  * HttpClient API get() method => Fetch donHang
  * @param id 
  * @returns 
  */
  find(id: any): Observable<DonHang> {
    return this.http.get<DonHang>(this.apiURL + '/don-hangs/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
  * HttpClient API get() method => paginate donHang with start page = 1 and limit
  * @param id 
  * @returns 
  */
  paginate(page: Number, limit: Number, filter: any): Observable<DonHang> {
    let url = this.apiURL + '/don-hangs/paginate?page=' + page + '&limit=' + limit;

    // add condition filter
    if (filter != '') {
      url = this.apiURL + '/don-hangs/paginate?page=' + page + '&limit=' + limit + filter;
    }
    return this.http.get<DonHang>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /*========================================
    Begin Custom Methods for RESTful API
  =========================================*/

  /**
   * Find gia tri don hang by id
   */
  findGiaTriDonHangById(id: any) {
    let url = this.apiURL + '/don-hangs/' + id + '/gia-tri-don-hang';
    return this.http.get<DonHang>(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   *  count is view 
   */
  getCountIsNotView() {
    return this.http.get<any>(this.apiURL + '/don-hangs/count-is-not-view')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * Update is view
   */
  updateIsView(id) {
    const body = {
      id,
      value: '1'
    }

    return this.http.put<any>(this.apiURL + '/don-hangs/update-is-view', JSON.stringify(body), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateAllIsView() {
    return this.http.put<any>(this.apiURL + '/don-hangs/update-all-is-view', this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * Error handling 
   * @param error 
   * @returns 
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}