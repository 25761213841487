export const Constant = {
  api: {
  //  frontend_url: 'https://p09fivess.izisoft.io/v1',
   //  frontend_url: 'https://p09fivess_test.izisoft.io/v1',
   //  frontend_url: 'http://localhost:3009/v1',
    frontend_url: 'https://p09fivesstest.izisoft.io/v1'
  },
  menu: {
    recusive: '/v1/menus/recusive',
  },

  role: {
    daiLy: '6168fb1362f385255f293af2',
    thoThau: '6168fb1362f385255f293af2',
  },

  TIEN_COC: {
    SO_TIEN: 10,
  },

  TRANG_THAI_DON_DICH_VU: {
    CHUA_PHAN_hOI: '616ce813cbb2cf21dad90cdf',
    DA_DUYET: '6170c4bbb3b6a47374f4e677',
    DA_BAO_GIA: '6170c66db3b6a47374f4e678',
    CHOT_GIA: '6170c682b3b6a47374f4e679',
    TRUNG_THAU: '6170c6b6b3b6a47374f4e67a',
    THAT_BAI: '617286fdf5c09a07f815e826',
    DA_HUY: '61970fcc31c0397701423ae0',
    DA_QUYET_TOAM: '618a1a71b7ce952de195ddc2',
  },

  LOAI_TAI_KHOAN: {
    DAI_LY: '6168fb1362f385255f293af2',
    KHACH_HANG: '6168fb1e62f385255f293afb',
    THO_THAU: '6168fb3062f385255f293b0b',
  },

  TRANG_THAI_DON_HANG: {
    MOI_TAO: '6169794b3391622ae920354b',
    TRA_HANG: '618a2968d89699350ec68704',
    HUY_DON: '616979743391622ae9203555',
  },

  TRANG_THAI_THANH_TOAN: {
    CHUA_THANH_TOAN: '61615180e87a9124404abe82',
    DA_THANH_TOAN: '61604f4cc8e6fa122227e29f',
    DAT_COC: '618232342960f9475ac1437c',
  },

  TRANG_THAI_TUYEN_DUNG: {
    DA_DUYET: '6162b8be062fb4833802ec89',
    CHUA_DUYET: '6162b79bd3d3e9825095fb20',
  },

  PAGINATION: {
    PAGE_INDEX: 0,
    PAGE_SIZE: 10,
    PAGE_SIZE_OPTIONS: [10, 20, 50, 100],
  },

  SORTING_TYPE: {
    DESC: 'desc',
    ASC: 'asc',
  },

  FORM_TYPE: {
    ADD: 'add',
    EDIT: 'edit',
    DETAIL: 'detail',
  },

  IMAGE_DEFAULT: 'assets/images/common/default.png',
};
